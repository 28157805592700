.About{
    width: 100vw;
    height: 100vh;
    background-color: #C1B4AF;
    align-items: flex-end;
    overflow: hidden;
}

.About .grid{
    display: grid;
	grid-template-columns: repeat(20,5%);
	grid-template-rows: repeat(20,5%);
    height: calc(100% - 84px);
    width: 100%;

}

.About  .grid__item{
    grid-area: 1/ 1 / 21 / 11;
    overflow: hidden;
}

.About  .grid__item-img{
    background-size: 200%;
}

.About  .Description{
    grid-area: 5/ 12 / 20 / 20;
    color: white;
}

.About .Description .word{
    display: inline-block;
}

.About .Description h1{
    font-weight: bold;
    font-size: 5.6vw;
}

.About .Description h2{
    /* font-family: 'Didot'; */
    font-size: 3vw;
    font-style: italic;
    margin: 50px 0  40px 0;
}

.About .Description p{
    width: 40vw;
    line-height: 2.5vw;
    font-size: 1.8vw;
    font-weight: 300;
}

.About  .Insta{
    color: white;
    font-size: 1rem;
    font-weight: 300;
    right: -25px;
    bottom: 60px;
    transform: rotate(270deg);
}

@media screen and (max-width: 768px) {
    .About .grid__item{
        grid-area: 1 / 2 / 14 / 21;
    }

    .About .Description{
        grid-area: 15/ 2 / 21 / 21 ;
    }

    .About .Description h1{
        font-size: 7vw;
        margin: 0;
    }

    .About .Description h2{
        margin: 0;
    }

    .About .Description p{
        width: 80%;
        line-height: 3vw;
    }
}

@media screen and (max-width: 411px){
    .About .Description h2{
        font-size: 1.75rem
    }

    .About .Description p{
        font-size: 1rem;
        line-height: 1rem;
    }
}

@media screen and (max-width: 375px){
    .About .Description p{
        font-size: 0.9rem;
        line-height: 0.9rem;
    }
}


