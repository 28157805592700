.RealGrid{
    height: 100vh;
	width: 100vw;
	background-color: #D2C2BA;
    overflow: hidden;
    position: relative;
}

/* ----- SET UP DE LA GRID ----- */
.RealGrid .grid{
	position: absolute;
	width: 110%;
	height: 110%;
	top: -5%;
	left: -5%;
	display: grid;
	grid-template-columns: repeat(50,2%);
	grid-template-rows: repeat(50,2%);
}

.RealGrid h1{
	grid-area: 30 / 15 / 47 / 50;
	z-index: 2;
	font-size: 11vw;
	color: #F9F9F9;
}

.RealGrid .hidingSpan{
	overflow: hidden;
	display: inline-block;
}

.RealGrid .wordTitle{
	display: inline-block;
	cursor: pointer;
}

/* ----- ANIMATION DE CHANGEMENT DE PAGE ----- */
.BackgroundExit{
	background-color: #C1B4AF;
	width: 100vw;
	height: 100vh;
}

@media screen and (max-width: 411px) {
	.RealGrid h1{
		font-size: 15vw
	}

}

