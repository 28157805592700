.Navbar{
    width: 100vw;
    position: absolute;
    z-index: 1;
}

.Navbar > div{
    padding: 25px 0 25px 0;
    font-family: 'Didot';
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    /* color: #9AA0A1; */

}

.Navbar .Navbar-Logo{
    font-family: 'Helvetica Neue';
    font-weight: bold;
    font-size: 1.7rem;
}

.Navbar .NavLink{
    margin-right: 20px;
    /* color: #9AA0A1; */
    text-decoration: none;
    font-size: 1.2rem;
}

.Navbar .NavLink::before{
    content: '';
    height: 0.07rem;
    width: 0px;
    background-color: #414141;
    position : absolute;
    top: 2.5rem;
    transition: ease-in 200ms;
}

.Navbar .NavLink:hover{
    color:#414141;
    font-family: "Helvetica Neue";
    font-weight: bold;
}

@media screen and (min-width: 1920px) {
    .Navbar .NavLink{
        font-size: 2rem;
    }
}




