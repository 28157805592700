.Visio{
	width: 100vw;
	height: 100vh;
	left: 100vw;
	background-color: #a5b3c5;
	z-index: 1	;
}

.Visio .NavGallery{
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-weight: 900;
    padding: 0 20px; 

}

.Visio .NavGallery .backButton{
    transform: rotate(0);
}

.NavGallery .navButton:hover{
    color : #292D26;
	cursor: pointer;
}

.Visio section{
    width: 100%;
    height: 100%;
    display: grid;
	grid-template-columns: repeat(20,5%);
	grid-template-rows: repeat(20,5%);
}

.Visio .test{
    grid-area: 3/ 9 / 19 / 20;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.Visio .main{
    width: 80%;
    height: 100%;
}

.Visio .main .grid__item-img{
    background-size: cover;
}


.Visio .Legende{
    width: 20%;
    color: white;
    font-family: 'Helvetica Neue';

}

.Visio .Legende h2{
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.Visio .Legende p{
    font-weight: 300;
    font-size: 1rem;
}


.Visio .secondary{
    grid-area: 13/ 2 / 19 / 5;
}

.Visio .Legende span{
    display: inline-block;
}

.Visio .clickable{
    z-index: 10;
}

@media screen and (max-width :768px ){
    .Visio .test{
        grid-area: 2/ 1 / 14 / 18;
        flex-direction: column;
    }

    .Visio .test .Legende{
        display: flex;
        width: 70%;
        justify-content: space-between;
        align-items: flex-start;
        order: 2;
        margin: 5px 0;
    }

    .Visio .secondary{
        grid-area: 15 / 7 / 20 / 15;
    }


}
