.Gallery{
    width: 100vw;
    height: 100vh;
	position: relative;
	background-color: #C1B4AF;
	margin: 0;
	padding:0;
	/* overflow-y: hidden; */

}

.Gallery .gridContainer{
	width: 700vw;
	height: 100vh;
	position:relative;
	background-color: #C1B4AF;
}

/* .Gallery .gridContainer{
	width: 50vw;
	height: 100vh;
} */


.Gallery .grid{
	position: absolute;
	background-color: #C1B4AF;
	width: 100%;
	height: 110%;
	top: -5%;
	left: -5%;
	display: grid;
	grid-template-columns: repeat(100,1%);
	grid-template-rows: repeat(50,2%);
	z-index: -1;
}

.Gallery .navGallery{
	font-weight: 900;
	font-style: italic;
	color: ivory;
	z-index:2;
}

.Gallery .backButton{
	bottom : 10%;
	transform: rotate(270deg);
}

.Gallery .mode1{
	top: 3%;
	left: 51px;
}

.Gallery .mode2{
	top: 10%; 
	left: 51px;
}

.Gallery .navGallery:hover{
	color : #292D26;
	cursor: pointer;
}

.Gallery .grid__item{
	cursor: pointer;
}

.Gallery .grid__item-img{
	/* background-size: 90%!important; */
	will-change: transform;
}

/*  ----- REGULAR GALLERY GRID__ITEM POSITION -----*/
.Gallery .pos-1{
	grid-area: 20 / 5 / 47 / 11
}

.Gallery .pos-2{
	grid-area: 4 / 12 / 27 / 15
}

.Gallery .pos-3{
	grid-area: 30 / 14 / 50 / 20
}

.Gallery .pos-4{
	grid-area: 7 / 17 / 43 / 24;
	z-index: -1
}

.Gallery .pos-5{
	grid-area: 11 / 25 / 50 / 35
}
.Gallery .pos-6{
	grid-area: 1 / 36 / 24 / 41
}

.Gallery .pos-7{
	grid-area: 26 / 37 / 50 / 40
}

.Gallery .pos-8{
	grid-area: 7 / 42 / 34 / 50
}

.Gallery .pos-9{
	grid-area: 36 / 41 / 50 / 48
}

.Gallery .pos-10{
	grid-area: 32 /51 / 50 / 57
}

.Gallery .pos-11{
	grid-area: 4 / 53 / 35 / 60
}

.Gallery .pos-12{
	grid-area: 26 / 59 / 48 / 65
}

.Gallery .pos-13{
	grid-area: 1 / 61 / 24 / 71
}

.Gallery .pos-14{
	grid-area: 25 / 66 / 47 / 72
}

.Gallery .pos-15{
	grid-area: 1 / 73 / 50 / 76
}

.Gallery .pos-16{
	grid-area: 30 / 77 / 49 / 85
}

.Gallery .pos-17{
	grid-area:8 / 84 / 38 / 90
}

.Gallery .pos-18{
	grid-area: 5 / 78 / 27 / 83
}

.Gallery .pos-19{
	grid-area: 7 / 91 / 50 / 94
}

.Gallery .pos-20{
	grid-area: 1 / 95 / 24 /100
}

.Gallery .pos-21{
	grid-area: 25 / 96 / 48 / 101
}

.Gallery .pos-22{
	grid-area: 36 / 41 / 50 / 48
}

.Gallery .pos-23{
	display: none;
}

.Gallery .pos-24{
	display: none;
}

.Gallery .pos-25{
	display: none;
}

.Gallery .pos-26{
	display: none;
}

.Gallery .pos-27{
	display: none;
}

/*  VISIO GRID-ITEM POSITION  */



