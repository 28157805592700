body{

    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    overscroll-behavior: none;
    /* CURSOR STYLING */
    --cursor-stroke: #ffffff;
    --cursor-fill: none;
    --cursor-stroke-width: 1px;
}

* a{
    color: inherit;
    text-decoration: none;
}

* a:focus{
    color: inherit;
}

@media (any-pointer: fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		opacity: 0;
	}
	.cursor__inner {
		fill: var(--cursor-fill);
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
	}
}

