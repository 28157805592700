.Description{
    height: 110vh;
	width: 100vw;
    overflow: hidden;
    position: relative;
}

.Description .grid{
	position: absolute;
	width: 110%;
	height: 110%;
	top: -5%;
	left: -5%;
	display: grid;
	grid-template-columns: repeat(50,2%);
	grid-template-rows: repeat(50,2%);
}

.Description section{
    grid-area: 20 / 10 / 40 / 40;
    color: #292D26;
    z-index: 1;
}

.Description section h2{
    font-size: 7vw;
    font-weight: bold;
}

.Description section p{
    font-size: 1.5rem;
    width: 60%;
    line-height: 45px;
}

.Homepage .Description .grid__item{
    background-size: 0;
    width: 0;
    justify-self: center;
}

.Description .grid__item.pos-1{
    grid-area: 10/ 28/ 45/ 50;
}

.Description .grid__item.pos-2{
    grid-area: 13/ 1/ 36/ 7;
}

.Description .grid__item.pos-3{
    grid-area: 37/ 16/ 46/ 26;
}

@media screen and (max-width: 768px) {
    .Description section{
        grid-area: 15 / 10 / 40 / 40;
    }
}

@media screen and (max-width: 411px){
    .Description section{
        grid-area: 15 / 10 / 40 / 50;
    }

    .Description section h2{
        font-size: 10vw;
        margin-bottom: 10px;
    }

    .Description section p{
        font-size: 5vw;
        width: 80%;
    }
}
