.PhotoGrid{
    width: 90%;
    height: 100vh;
    margin: 30px auto;
    transition: ease-in 200ms all;
}

.MainPhoto{
    width: 80%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in;
}

.MainPhoto .Photo-Container{
    width: 100%;
    height: 100%;
    margin: auto auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position : center;
}

