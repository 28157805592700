.HeroBanner{
    height: calc(97vh - 65px);
    width: 100%;
    color: #414141;
    align-items: flex-end;
    margin-top: 88px;

}

.HeroBanner #title{
    color: #292D26;
    margin-left: 50px;
    font-family: 'Didot';
    font-size: 5.3vw;
    overflow : hidden;
    z-index: 1;
    line-height: 10vw;
}


.HeroBanner-story{
    font-size: 1.5vw;    
    align-self: flex-end;
    font-weight: 400; 
    color:#414141;
    font-family:Helvetica, sans-serif;
    padding-right: 50px;
}

.HeroBanner-story:before{
    font-size: 2.25vw;    
    font-weight: bold; 
    color:white;
    content: "A short story made by Etienne Glénat";
    position: absolute;
    clip-path: polygon(0 0, 14vw 0%, 14vw 100%, 0% 100%);
    z-index: 3;
}


.HeroBanner .madeBy {
    left: -70px;
    bottom: 100px;
    transform: rotate(270deg) !important;
}

.HeroBanner-midpage{
    width: 100%;
    height: 70vh;
    flex-direction: column;
    justify-content: space-around;
}

.HeroBanner-img{
    width: 58%;
    z-index: 0;
    bottom: -10%;
    left: 10%;
    object-fit: cover;
}

@media screen and (max-width: 411px) {
    .HeroBanner .HeroBanner-midpage{
        justify-content: flex-start;
    }

    .HeroBanner h1{
        font-size: 13vw;
        line-height: 3rem;
    }

    .HeroBanner h2{
        font-size: 3vw;
        line-height: 3rem;
    }

    .HeroBanner .HeroBanner-story{
        margin-top: 50px;
    }

    .HeroBanner .HeroBanner-story:before{
        display: none;
    }

    .HeroBanner .HeroBanner-img{
        bottom: 72px;
        width: 77% !important;
    }

    .HeroBanner .madeBy{
        left: 10px;
        bottom: 30px;
        transform: rotate(0deg) !important;

    }
}

@media screen and (max-width: 768px) {
    .HeroBanner .HeroBanner-midpage{
        justify-content: flex-start;
    }

    .HeroBanner h1{
        font-size: 14vw;
        line-height: 7rem;
    }

    .HeroBanner .HeroBanner-story{
        margin-top: 50px;
        font-size: 2vw;
    }

    .HeroBanner .HeroBanner-story:before{
        font-size: 3vw;
        clip-path: polygon(0 0, 46.9vw 0%, 46.9vw 100%, 0% 100%);
    }

    .HeroBanner .HeroBanner-img{
        bottom: 72px;
        width: 77% !important;
    }
}

@media screen and (min-width: 1920px) {
    .HeroBanner .HeroBanner-story:before{
        clip-path: polygon(0 0, 12.6vw 0%, 12.6vw 100%, 0% 100%);
    }

    /* .HeroBanner .madeBy{
        left: -30px
    } */
}

