.IntroductionHero{
    width: 90%;
    height: calc(93vh - 84px);
    margin: 0 auto;
    align-items: flex-end;
}

/* .IntroductionHero-Line{
    border: solid 1px red
} */

.IntroductionHero-Main{
    grid-column: span 8;
    font-size: 1.8rem;
    line-height: 4rem;
    letter-spacing: 0.5rem;
    color :#313131;
    overflow: hidden;
    /* padding-right: 50px; */
}

.IntroductionHero-Main span{
    display: inline-block;
    z-index: 0;
}

.IntroductionHero-Main .word{
    display: inline-block;
}

.IntroductionHero-Secondary{
    grid-column: span 4;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    color: #9AA0A1;
    text-align: end;
}

.IntroductionHero-Secondary .Collection{
    padding-top: 3rem;
}

.IntroductionHero-Secondary h3{
    margin: 20px 0;
    overflow: hidden;
}
.IntroductionHero-Secondary h4 {
    margin: 20px 0;
}
.IntroductionHero-Secondary h5 {
    margin: 20px 0;
}

.IntroductionHero-Secondary .PageNumber{
    font-size: 1.5rem;
    font-family: 'Avenir';
}