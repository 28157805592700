/* SET UP DE PAGE */
*{
    padding : 0;
    margin: 0;
    box-sizing: border-box;
}

/*  FUNCTIONS I WILL USE THROUGHOUT ALL MY CODING SESSION */
.text-center{
    text-align: center;
}

.flex{
    display: flex;
}

.relative{
    position: relative;
}

.absolute{
    position: absolute;
}

.fixed{
    position: fixed;
}

.border{
    border: 1px solid red;
}

.container{
    width: 90vw;
    margin: 0 auto;
}

.page{
    width: 100%;
    height: 100vh;
}

.resp{
    width: 100%;
    object-fit: coverx;
}

.row{
    display: grid;
    grid-template-columns: repeat(12,1fr) ;
  }

.grid-space{
    grid-column: span 3;
}

.overflowHidden{
    overflow : hidden;
}

.hidden{
    display : none;
}

.hidingSpan{
    overflow: hidden;
	display: inline-block;
}

/* Transition de page */
.pageTransition{
    height: 130vh;
    width: 100vw;
    background: #b4acac;
    /* border-radius: 50%; */
    /* right: -40px;
    top: -100px; */
}