
.grid__item {
	position: relative;
	
}

.grid__item-img {
	position: relative;
	width: 100%;
	height: 100%;
	background-size: contain;
	background-position: 10% 20%;
}

/* ----- SET UP DES STYLES POUR CRÉER L'EFFET QUAND HOVER SOURIS ----- */

.RealGrid .grid__item {
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	will-change: transform;
	margin: 0;

}

.RealGrid .grid__item-img {
	flex: none;
	width: calc(100% + 100px);
	height: 0;
	will-change: transform;
	background-position: cover;
	background-size : 80%;
}

/* ----- POSITIONNEMENT DES PREMIÈRES PHOTOS ----- */
/* grid-area: grid-row-start / grid-column-start / grid-row-end / grid-column-end */
/* PECHEURS */
.RealGrid .pos-1 {
	grid-area: 25 / 1 / 47 / 7;
}

/* ELIOTT */
.RealGrid .pos-2 {
	grid-area: 1 / 9 / 35 / 16;
}

/* FLEURS */
.RealGrid .pos-3 {
	grid-area: 10 /17 / 50 / 24;
}

/* LUCIE */
.RealGrid .pos-4 {
	grid-area: 41 / 23 / 50 / 40;
}

.RealGrid .pos-5{
	grid-area: 4 / 25 / 30 / 32;
}

.RealGrid .pos-6{
	grid-area: 3 / 33 / 40 / 40;
}

.RealGrid .pos-7{
	grid-area: 28 / 41 / 50 / 46;
}

.RealGrid .pos-8{
	grid-area: 7 / 45 / 47 / 50;
}

