.grid{
    grid-column: span 2;
    grid-auto-flow: column;
}

.vision{
    /* display: flex; */
    grid-column: 1;
    width: 200%;
    height: 50px;
    margin: 10px 0;
    /* display: inline; */
}

.vision .PhotoGrid-Legende{
    display: none;
}


.PhotoGridComponent .Photo{
    width: 50%;
    height: 200px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    transition: all 200ms ease-in;
}

.PhotoGridComponent.grid .Photo:hover{
   width: 100%;
}

.PhotoGridComponent.vision .Photo:hover{
    transform: scale(1.05);
    /* width: 50%; */
}

.PhotoGridComponent .Photo:hover .PhotoGrid-Legende{
    width: 0%;
}

.PhotoGrid-Legende{
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 1rem;
}

.PhotoGrid-Legende h4{
    color : #313131;
    font-size: 0.7rem;

}

.PhotoGrid-Legende p{
    color : #9AA0A1;
    font-size: 0.6rem;
    margin-top: 0.6rem;
}

.PhotoKingSize{
    width: 50vw;
    position: absolute;
    right: -70vw;
}