@font-face{
  font-family: "Monument Extended";
  src: url('/src/fonts/MonumentExtended-Ultrabold.otf') format("otf"),      
}

@font-face{
  font-family: "Didot";
  src: url('/src/fonts/Didot\ LT\ Std\ Bold.otf') format("otf"),      
}

body {
  /* Classic SET UP */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Helvetica Neue' , sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #F9F9F9;
}
